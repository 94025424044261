import React from 'react'
import { RadioGroupOption as Option } from '@headlessui/react'

export interface OptionRenderPropArg {
    checked: boolean
    active: boolean
    disabled: boolean
    hasError?: boolean
    children?: React.ReactNode
}

const RadioGroupOption: React.FC<{
    value: unknown
    disabled?: boolean
    children: (props: OptionRenderPropArg) => JSX.Element
}> = ({ value, disabled, children }) => (
    <Option value={value} disabled={disabled}>
        {(props) => children(props)}
    </Option>
)

export default RadioGroupOption
