import { FormikErrors, Form } from 'formik'
import { useTranslation } from 'react-i18next'

import { Button, Label } from './ui'
import InfiniteDropdownWithSearchFilter from './ui/InfiniteDropdownWithSearchFilter'
import { TextField } from './forms'

import type { UserForm } from '../types'
import { placeMapFn } from 'helpers'
import { getPlaces } from 'api'

type UserProps = {
    values: UserForm
    setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
    ) => Promise<void | FormikErrors<UserForm>>
    isSubmitting: boolean
    readonly?: boolean
}

export default function UserFeatures({
    values,
    setFieldValue,
    isSubmitting,
    readonly = false,
}: UserProps) {
    const { t } = useTranslation()

    return (
        <Form>
            <div className="mt-8">
                <div className="">
                    <div className="">
                        <div className="border-t border-gray-600 my-5"></div>
                        <div className="mr-auto max-w-3xl grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div className="col-span-1">
                                <Label>{t('form.user.field.country')}</Label>
                                <InfiniteDropdownWithSearchFilter
                                    name="country"
                                    queryFn={getPlaces}
                                    disabled={readonly}
                                    value={values.country}
                                    mapFn={placeMapFn}
                                    setValue={(value) => {
                                        if (values.country?.id !== value?.id) {
                                            setFieldValue('city', undefined)
                                        }
                                        setFieldValue('country', value)
                                    }}
                                    queryFilters={{
                                        sort_by: 'name',
                                        sort_direction: 'asc',
                                        type: 'country',
                                    }}
                                />
                            </div>
                            <div className="col-span-1">
                                <Label>{t('form.user.field.region')}</Label>
                                <TextField name="region" disabled={readonly} />
                            </div>
                        </div>
                        <div className="border-t border-gray-600 my-5"></div>
                        <div className="mr-auto max-w-3xl grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div className="col-span-1">
                                <Label>{t('form.user.field.city')}</Label>
                                <InfiniteDropdownWithSearchFilter
                                    name="city"
                                    queryFn={getPlaces}
                                    value={values.city}
                                    mapFn={placeMapFn}
                                    disabled={readonly || !values.country?.id}
                                    setValue={(value) =>
                                        setFieldValue('city', value)
                                    }
                                    queryFilters={{
                                        sort_by: 'name',
                                        sort_direction: 'asc',
                                        country_id: values.country?.id,
                                        type: 'city',
                                    }}
                                    key={`dropodown-city-${values.country?.id}`}
                                />
                            </div>
                        </div>
                        <div className="border-t border-gray-600 my-5"></div>
                    </div>
                </div>
                {!readonly && (
                    <div className="flex mt-8 pt-5 items-center justify-between">
                        <div className="flex-1"></div>
                        <div className="flex-1 flex items-center justify-end ml-3">
                            <Button variant="secondary" as="link" to="/user">
                                {t('form.cancel')}
                            </Button>
                            <Button
                                type="submit"
                                variant="primary"
                                className="ml-3"
                                loading={isSubmitting}
                            >
                                {t('form.save')}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </Form>
    )
}
