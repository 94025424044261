import React from 'react'
import { useTranslation } from 'react-i18next'

import { Label, Dropdown, SearchField } from './ui'

import {
    DropdownItem,
    StatisticsDetailsListFilterAction,
    StatisticsDetailsListFilterState,
} from '../types'
import {
    useFoilSizesDropdown,
    useFoilTypesDropdown,
    useUserRoles,
} from '../hooks'
import { getPlaces } from 'api/endpoints/places'
import { getDevices } from 'api/endpoints/devices'
import { getPattern } from 'api/endpoints/patterns'
import InfiniteDropdownWithSearch from './ui/InfiniteDropdownWithSearchFilter'
import { getPlotter, getUsers } from 'api'
import {
    supervisorMapFn,
    deviceMapFn,
    userMapFn,
    patternMapFn,
    plotterMapFn,
    placeMapFn,
} from 'helpers'
import { useFeedbackTypes } from 'hooks/useFeedbackTypes'

const StatisticsDetailsListFilters: React.FC<{
    filters: StatisticsDetailsListFilterState
    filterAction: React.Dispatch<StatisticsDetailsListFilterAction>
}> = ({ filters, filterAction }) => {
    const { t } = useTranslation()
    const userRoles = useUserRoles()

    const ALL_ITEM: DropdownItem = { id: '', name: t('dictionary.all') }
    const foilTypes = useFoilTypesDropdown()
    const foilSizes = useFoilSizesDropdown()
    const feedbackTypes = useFeedbackTypes()

    return (
        <div className="mt-3 px-4 py-3 mb-4 bg-gray-800 rounded-lg">
            <div className="grid grid-cols-5 gap-4">
                <div>
                    <Label>{t('filters.superior')}</Label>
                    <div className="shadow-sm rounded-md">
                        <InfiniteDropdownWithSearch
                            value={filters.superior}
                            queryFilters={{ roles: [userRoles.supervisor.id] }}
                            queryFn={getUsers}
                            name="superiors"
                            mapFn={supervisorMapFn}
                            setValue={(value: DropdownItem | undefined) => {
                                filterAction({
                                    type: 'setFilterSuperior',
                                    payload: value
                                        ? value.id
                                            ? value
                                            : undefined
                                        : undefined,
                                })
                            }}
                        />
                    </div>
                </div>
                <div>
                    <Label>{t('filters.user')}</Label>
                    <div className="shadow-sm rounded-md">
                        <InfiniteDropdownWithSearch
                            value={filters.user}
                            queryFilters={{ roles: [userRoles.user.id] }}
                            queryFn={getUsers}
                            name="users"
                            mapFn={userMapFn}
                            setValue={(value: DropdownItem | undefined) => {
                                filterAction({
                                    type: 'setFilterUser',
                                    payload: value
                                        ? value.id
                                            ? value
                                            : undefined
                                        : undefined,
                                })
                            }}
                        />
                    </div>
                </div>
                <div>
                    <Label>{t('filters.country')}</Label>
                    <div className="shadow-sm rounded-md">
                        <InfiniteDropdownWithSearch
                            value={filters.country}
                            queryFilters={{
                                sort_by: 'name',
                                sort_direction: 'asc',
                                type: 'country',
                            }}
                            queryFn={getPlaces}
                            name="country"
                            mapFn={placeMapFn}
                            setValue={(value: DropdownItem | undefined) => {
                                filterAction({
                                    type: 'setFilterCountry',
                                    payload: value
                                        ? value.id
                                            ? value
                                            : undefined
                                        : undefined,
                                })
                            }}
                        />
                    </div>
                </div>
                <div>
                    <Label>{t('filters.region')}</Label>
                    <div className="shadow-sm rounded-md">
                        <SearchField
                            value={filters.region}
                            action={(value) => {
                                filterAction({
                                    type: 'setFilterRegion',
                                    payload: value || undefined,
                                })
                            }}
                        />
                    </div>
                </div>
                <div>
                    <Label>{t('filters.city')}</Label>
                    <div className="shadow-sm rounded-md">
                        <InfiniteDropdownWithSearch
                            value={filters.city}
                            queryFilters={{
                                sort_by: 'name',
                                sort_direction: 'asc',
                                type: 'city',
                                country_id: filters.country?.id,
                            }}
                            queryFn={getPlaces}
                            disabled={!filters.country?.id}
                            name="city"
                            mapFn={placeMapFn}
                            setValue={(value: DropdownItem | undefined) => {
                                filterAction({
                                    type: 'setFilterCity',
                                    payload: value
                                        ? value.id
                                            ? value
                                            : undefined
                                        : undefined,
                                })
                            }}
                            key={`dropodown-city-${filters.country?.id}`}
                        />
                    </div>
                </div>
                <div>
                    <Label>{t('filters.plotter')}</Label>
                    <div className="shadow-sm rounded-md">
                        <InfiniteDropdownWithSearch
                            value={filters.plotter}
                            queryFn={getPlotter}
                            name="plotters"
                            mapFn={plotterMapFn}
                            queryFilters={{ assigned: true }}
                            setValue={(value: DropdownItem | undefined) => {
                                filterAction({
                                    type: 'setFilterPlotter',
                                    payload: value
                                        ? value.id &&
                                          value.id !== filters.plotter?.id
                                            ? value
                                            : undefined
                                        : undefined,
                                })
                            }}
                        />
                    </div>
                </div>
                <div>
                    <Label>{t('filters.device')}</Label>
                    <div className="shadow-sm rounded-md">
                        <InfiniteDropdownWithSearch
                            value={filters.device}
                            queryFn={getDevices}
                            name="devices"
                            mapFn={deviceMapFn}
                            setValue={(value: DropdownItem | undefined) => {
                                filterAction({
                                    type: 'setFilterDevice',
                                    payload: value
                                        ? value.id &&
                                          value.id !== filters.device?.id
                                            ? value
                                            : undefined
                                        : undefined,
                                })
                            }}
                        />
                    </div>
                </div>
                <div>
                    <Label>{t('filters.film')}</Label>
                    <div className="shadow-sm rounded-md">
                        <Dropdown
                            items={[ALL_ITEM].concat(foilTypes)}
                            value={
                                foilTypes.find(
                                    (item) => item.id === filters.type?.id
                                ) || ALL_ITEM
                            }
                            onChange={(value) => {
                                filterAction({
                                    type: 'setFilterType',
                                    payload: value
                                        ? value.id
                                            ? value
                                            : undefined
                                        : undefined,
                                })
                            }}
                        />
                    </div>
                </div>
                <div>
                    <Label>{t('filters.size')}</Label>
                    <div className="shadow-sm rounded-md">
                        <Dropdown
                            items={[ALL_ITEM].concat(foilSizes)}
                            value={
                                foilSizes.find(
                                    (item) => item.id === filters.size?.id
                                ) || ALL_ITEM
                            }
                            onChange={(value) => {
                                filterAction({
                                    type: 'setFilterSize',
                                    payload: value
                                        ? value.id
                                            ? value
                                            : undefined
                                        : undefined,
                                })
                            }}
                        />
                    </div>
                </div>
                <div>
                    <Label>{t('filters.pattern')}</Label>
                    <div className="shadow-sm rounded-md">
                        <InfiniteDropdownWithSearch
                            value={filters.pattern}
                            disabled={!filters.device?.id}
                            queryFilters={{ device_id: filters.device?.id }}
                            queryFn={getPattern}
                            name="patterns"
                            mapFn={patternMapFn}
                            setValue={(value: DropdownItem | undefined) => {
                                filterAction({
                                    type: 'setFilterPattern',
                                    payload: value
                                        ? value.id
                                            ? value
                                            : undefined
                                        : undefined,
                                })
                            }}
                        />
                    </div>
                </div>
                <div>
                    <Label>{t('filters.feedback_type')}</Label>
                    <div className="shadow-sm rounded-md">
                        <Dropdown
                            items={[ALL_ITEM].concat(feedbackTypes)}
                            value={
                                feedbackTypes.find(
                                    (item) =>
                                        item.id === filters.feedbackType?.id
                                ) || ALL_ITEM
                            }
                            onChange={(value) => {
                                filterAction({
                                    type: 'setFilterFeedbackStatus',
                                    payload: value
                                        ? value.id
                                            ? value
                                            : undefined
                                        : undefined,
                                })
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StatisticsDetailsListFilters
