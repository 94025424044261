import React from 'react'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'

import { Button, Label } from './ui'
import { DropdownField, TextField } from './forms'

import type { FormSubmitFn, GenerateQRCodesForm } from '../types'

import {
    useFoilSizesDropdown,
    usePressuresDropdown,
    useProtectionFilmTypeDropdown,
    useSpeedsDropdown,
} from '../hooks'

type QrGeneratorProps = {
    onSubmit: FormSubmitFn<GenerateQRCodesForm>
    readonly?: boolean
}

export default function QrGenerator({
    onSubmit,
    readonly = false,
}: QrGeneratorProps) {
    const { t } = useTranslation()

    const sizes = useFoilSizesDropdown()
    const types = useProtectionFilmTypeDropdown()
    const speeds = useSpeedsDropdown()
    const pressures = usePressuresDropdown()

    const emptyListItem = [{ id: 0, name: '' }]

    return (
        <Formik<GenerateQRCodesForm>
            initialValues={{
                size: sizes[0],
                protection_film_type_id: (types ?? emptyListItem)[0],
                count: 0,
                custom_speed:
                    types !== undefined
                        ? (speeds.dropdownItems.find(
                              (s) => s.id === types[0].speed
                          ) ?? speeds.default)
                        : speeds.default,
                custom_pressure:
                    types !== undefined
                        ? (pressures.dropdownItems.find(
                              (s) => s.id === types[0].pressure
                          ) ?? pressures.default)
                        : pressures.default,
            }}
            onSubmit={onSubmit}
        >
            {({ values, setFieldValue, isSubmitting }) => (
                <Form>
                    <div className="p-6 rounded-lg bg-gray-800">
                        <h2 className="text-xl leading-7 font-medium">
                            {t('form.qr.subtitle')}
                        </h2>
                        <div className="my-6 grid grid-cols-3 gap-4">
                            <div className="col-span-1">
                                <Label>{t('form.qr.field.size')}</Label>
                                <DropdownField
                                    name="size"
                                    value={values.size}
                                    items={sizes}
                                    disabled={readonly}
                                    anchor="top"
                                    onChange={(value) =>
                                        setFieldValue('size', value)
                                    }
                                />
                            </div>
                            <div className="col-span-1">
                                <Label>{t('form.qr.field.type')}</Label>
                                <DropdownField
                                    name="protection_film_type_id"
                                    value={values.protection_film_type_id}
                                    items={types ?? [{ id: 0, name: '' }]}
                                    disabled={readonly}
                                    anchor="top"
                                    onChange={(value) => {
                                        setFieldValue(
                                            'protection_film_type_id',
                                            value
                                        )
                                        const type = types?.find(
                                            (v) => v.id === value?.id
                                        )

                                        setFieldValue(
                                            'custom_speed',
                                            speeds.dropdownItems.find(
                                                (s) => s.id === type?.speed
                                            )
                                        )
                                        setFieldValue(
                                            'custom_pressure',
                                            pressures.dropdownItems.find(
                                                (s) => s.id === type?.pressure
                                            )
                                        )
                                    }}
                                />
                            </div>
                            <div className="col-span-1">
                                <Label>{t('form.qr.field.count')}</Label>
                                <TextField name="count" disabled={readonly} />
                            </div>
                        </div>

                        <div className="my-6 grid grid-cols-3 gap-4">
                            <div className="col-span-1">
                                <Label>{t('form.qr.field.speed')}</Label>
                                <DropdownField
                                    name="custom_speed"
                                    value={values.custom_speed}
                                    items={speeds.dropdownItems}
                                    disabled={readonly}
                                    anchor="top"
                                    onChange={(value) =>
                                        setFieldValue('custom_speed', value)
                                    }
                                />
                            </div>
                            <div className="col-span-1">
                                <Label>{t('form.qr.field.pressure')}</Label>
                                <DropdownField
                                    name="custom_pressure"
                                    value={values.custom_pressure}
                                    items={pressures.dropdownItems}
                                    disabled={readonly}
                                    anchor="top"
                                    onChange={(value) =>
                                        setFieldValue('custom_pressure', value)
                                    }
                                />
                            </div>
                        </div>

                        <div className="">
                            <Button
                                type="submit"
                                variant="primary"
                                loading={isSubmitting}
                            >
                                {t('form.generate')}
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    )
}
