import React from 'react'
import { Form, Formik } from 'formik'
import { DialogTitle } from '@headlessui/react'

import {
    defaultFrom,
    defaultTo,
    deviceMapFn,
    getCxFromStyles,
    lowerLimitDate,
    patternMapFn,
    placeMapFn,
    plotterMapFn,
    upperLimitDate,
    userMapFn,
} from '../helpers'
import Modal from './Modal'
import { Button, Dropdown, Label } from './ui'

import type {
    IModal,
    ExportForm,
    DropdownItem,
    FormSubmitFn,
    StatisticsDetailsListFilterState,
} from '../types'

import styles from './Modal.module.scss'
import {
    useFlashMessage,
    useFoilSizesDropdown,
    useFoilTypesDropdown,
    useUserRoles,
} from '../hooks'
import { useTranslation } from 'react-i18next'
import {
    getDevices,
    getPattern,
    downloadStatisticsXls,
    getUsers,
    getPlotter,
} from 'api'
import { getPlaces } from 'api/endpoints/places'
import moment from 'moment'
import { DatePicker } from './ui/DatePickers'
import InfiniteDropdownWithSearchFilter from './ui/InfiniteDropdownWithSearchFilter'
import Checkbox from './ui/Checkbox'
import { useFeedbackTypes } from 'hooks/useFeedbackTypes'

export type ExportModalProps = {
    modal: IModal<StatisticsDetailsListFilterState | undefined>
}

export default function ExportModal({ modal }: ExportModalProps) {
    const cx = getCxFromStyles(styles)
    const { t } = useTranslation()
    const userRoles = useUserRoles()
    const flashMessage = useFlashMessage()

    const ALL_ITEM: DropdownItem = { id: '', name: t('dictionary.all') }
    const foilTypes = useFoilTypesDropdown()
    const foilSizes = useFoilSizesDropdown()
    const feedbackTypes = useFeedbackTypes()

    const handleSubmit: FormSubmitFn<ExportForm> = async (
        values,
        formikHelpers
    ) => {
        try {
            const response = await downloadStatisticsXls({
                from: moment(values.from || defaultFrom).format('YYYY-MM-DD'),
                to: moment(values.to || defaultTo).format('YYYY-MM-DD'),
                superior_id: values.superior?.id || undefined,
                user_id: values.user?.id || undefined,
                country_id: values.country?.id || undefined,
                city_id: values.city?.id || undefined,
                device_id: values.device?.id || undefined,
                size: values.size?.id || undefined,
                type: values.type?.id || undefined,
                pattern_id: values.pattern?.id || undefined,
                cutter_id: values.plotter?.id || undefined,
                feedback_type: values.feedbackType?.id || undefined,
                with_wifi: values.wifi ?? false,
                with_skin: values.skin ?? false,
                with_ip: values.skin ?? false,
                with_lang: values.lang ?? false,
                export: true,
            })

            const href = URL.createObjectURL(
                new Blob([response.data], {
                    type: (response as any).headers['content-type'],
                })
            )

            // create "a" HTML element with href to file & click
            const link = document.createElement('a')
            link.href = href
            link.setAttribute(
                'download',
                `export-${moment(new Date()).format('DD-MM-yyyy')}.xls`
            ) //or any other extension
            document.body.appendChild(link)
            link.click()

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link)
            URL.revokeObjectURL(href)
            flashMessage({
                type: 'success',
                content: t('info.export.saved'),
            })
            formikHelpers.setSubmitting(false)

            modal.closeModal()
        } catch (error: any) {
            flashMessage({
                type: 'danger',
                content: t('info.export.failed'),
            })
            formikHelpers.setErrors(error.errors)
            formikHelpers.setSubmitting(false)
        }
    }

    const initialState = modal.getState()

    return (
        <Modal modal={modal} type="default" className="sm:max-w-5xl">
            <div>
                <DialogTitle
                    as="h3"
                    className="text-lg leading-6 font-medium text-white text-center"
                >
                    {t('form.export.title')}
                </DialogTitle>
                <Formik<ExportForm>
                    onSubmit={handleSubmit}
                    initialValues={{
                        from: initialState?.from,
                        to: initialState?.to,
                        superior: initialState?.superior,
                        user: initialState?.user,
                        country: initialState?.country,
                        city: initialState?.city,
                        device: initialState?.device,
                        size: initialState?.size,
                        type: initialState?.type,
                        pattern: initialState?.pattern,
                        plotter: initialState?.plotter,
                        feedbackType: initialState?.feedbackType,
                    }}
                >
                    {({ isSubmitting, values, setFieldValue }) => (
                        <Form className="px-2 pb-2">
                            <div className="py-6 text-gray-400 text-sm leading-5 font-normal">
                                {t('form.export.data_label')}
                            </div>
                            <div className="mr-auto grid grid-cols-4 gap-4">
                                <div className="col-span-1">
                                    <Label>
                                        {t('form.export.field.supervisor')}
                                    </Label>
                                    <InfiniteDropdownWithSearchFilter
                                        name="superior"
                                        queryFn={getUsers}
                                        value={values.superior}
                                        mapFn={userMapFn}
                                        setValue={(value) =>
                                            setFieldValue('superior', value)
                                        }
                                        queryFilters={{
                                            roles: [userRoles.supervisor.id],
                                        }}
                                    />
                                </div>
                                <div className="col-span-1">
                                    <Label>
                                        {t('form.export.field.client')}
                                    </Label>
                                    <InfiniteDropdownWithSearchFilter
                                        name="user"
                                        queryFn={getUsers}
                                        value={values.user}
                                        mapFn={userMapFn}
                                        setValue={(value) =>
                                            setFieldValue('user', value)
                                        }
                                        queryFilters={{
                                            roles: [userRoles.user.id],
                                        }}
                                    />
                                </div>
                                <div className="col-span-1">
                                    <Label>
                                        {t('form.export.field.country')}
                                    </Label>
                                    <InfiniteDropdownWithSearchFilter
                                        value={values.country}
                                        queryFilters={{ type: 'country' }}
                                        queryFn={getPlaces}
                                        name="country"
                                        mapFn={placeMapFn}
                                        setValue={(
                                            value: DropdownItem | undefined
                                        ) => {
                                            if (
                                                values.country?.id !== value?.id
                                            ) {
                                                setFieldValue('city', undefined)
                                            }
                                            setFieldValue('country', value)
                                        }}
                                    />
                                </div>
                                <div className="col-span-1">
                                    <Label>{t('form.export.field.city')}</Label>
                                    <InfiniteDropdownWithSearchFilter
                                        value={values.city}
                                        queryFilters={{
                                            type: 'city',
                                            country_id: values.country?.id,
                                        }}
                                        queryFn={getPlaces}
                                        name="city"
                                        mapFn={placeMapFn}
                                        disabled={!values.country?.id}
                                        setValue={(
                                            value: DropdownItem | undefined
                                        ) => {
                                            setFieldValue('city', value)
                                        }}
                                        key={`dropodown-city-${values.country?.id}`}
                                    />
                                </div>
                                <div className="col-span-1">
                                    <Label>{t('filters.plotter')}</Label>
                                    <InfiniteDropdownWithSearchFilter
                                        value={values.plotter}
                                        queryFn={getPlotter}
                                        name="plotters"
                                        mapFn={plotterMapFn}
                                        queryFilters={{ assigned: true }}
                                        setValue={(
                                            value: DropdownItem | undefined
                                        ) => {
                                            setFieldValue('plotter', value)
                                        }}
                                    />
                                </div>
                                <div className="col-span-1">
                                    <Label>
                                        {t('form.export.field.device')}
                                    </Label>
                                    <InfiniteDropdownWithSearchFilter
                                        name="device"
                                        value={values.device}
                                        queryFn={getDevices}
                                        mapFn={deviceMapFn}
                                        setValue={(value) => {
                                            setFieldValue('device', value)
                                            setFieldValue('pattern', undefined)
                                        }}
                                    />
                                </div>
                                <div className="col-span-1">
                                    <Label>{t('form.export.field.film')}</Label>
                                    <Dropdown
                                        value={values.size || ALL_ITEM}
                                        items={[ALL_ITEM, ...foilSizes]}
                                        onChange={(value) =>
                                            setFieldValue('size', value)
                                        }
                                    />
                                </div>
                                <div className="col-span-1">
                                    <Label>{t('form.export.field.type')}</Label>
                                    <Dropdown
                                        value={values.type || ALL_ITEM}
                                        items={[ALL_ITEM, ...foilTypes]}
                                        onChange={(value) =>
                                            setFieldValue('type', value)
                                        }
                                    />
                                </div>
                                <div className="col-span-1">
                                    <Label>
                                        {t('form.export.field.pattern')}
                                    </Label>
                                    <InfiniteDropdownWithSearchFilter
                                        name="pattern"
                                        value={values.pattern}
                                        queryFn={getPattern}
                                        mapFn={patternMapFn}
                                        disabled={!values.device?.id}
                                        queryFilters={{
                                            device_id: values.device?.id,
                                        }}
                                        setValue={(value) =>
                                            setFieldValue('pattern', value)
                                        }
                                    />
                                </div>
                                <div className="col-span-1">
                                    <Label>
                                        {t('form.export.field.feedback')}
                                    </Label>
                                    <Dropdown
                                        value={values.feedbackType || ALL_ITEM}
                                        items={[ALL_ITEM, ...feedbackTypes]}
                                        onChange={(value) =>
                                            setFieldValue('feedBackType', value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="py-6 text-gray-400 text-sm leading-5 font-normal">
                                {t('form.export.extra_label')}
                            </div>
                            <div className="mr-auto grid grid-cols-2 gap-4">
                                <div className="col-span-1">
                                    <Checkbox
                                        name={t('form.export.field.wifi')}
                                        value={values.wifi || false}
                                        onChange={(value) =>
                                            setFieldValue('wifi', value)
                                        }
                                    />
                                </div>
                                <div className="col-span-1">
                                    <Checkbox
                                        name={t('form.export.field.ip')}
                                        value={values.ip || false}
                                        onChange={(value) =>
                                            setFieldValue('ip', value)
                                        }
                                    />
                                </div>
                                <div className="col-span-1">
                                    <Checkbox
                                        name={t('form.export.field.language')}
                                        value={values.lang || false}
                                        onChange={(value) =>
                                            setFieldValue('lang', value)
                                        }
                                    />
                                </div>
                                <div className="col-span-1">
                                    <Checkbox
                                        name={t('form.export.field.theme')}
                                        value={values.skin || false}
                                        onChange={(value) =>
                                            setFieldValue('skin', value)
                                        }
                                    />
                                </div>
                            </div>
                            <div className="mr-auto grid grid-cols-2 gap-4 pt-8">
                                <span className="col-span-1">
                                    <Label>{t('form.export.field.from')}</Label>
                                    <DatePicker
                                        disabledBefore={lowerLimitDate}
                                        disabledAfter={
                                            values.to
                                                ? values.to
                                                : upperLimitDate
                                        }
                                        value={values.from}
                                        onChange={(value) =>
                                            value &&
                                            setFieldValue('from', value)
                                        }
                                        // captionLayout='dropdown-buttons'
                                    />
                                </span>
                                <span className="col-span-1">
                                    <Label>{t('form.export.field.to')}</Label>
                                    <DatePicker
                                        disabledBefore={
                                            values.from
                                                ? values.from
                                                : lowerLimitDate
                                        }
                                        disabledAfter={upperLimitDate}
                                        value={values.to}
                                        onChange={(value) =>
                                            value && setFieldValue('to', value)
                                        }
                                        // captionLayout='dropdown-buttons'
                                    />
                                </span>
                            </div>
                            <div className={cx('action-container')}>
                                <Button
                                    tabIndex={-1}
                                    variant="secondary"
                                    type="button"
                                    onClick={modal.closeModal}
                                >
                                    {t('form.cancel')}
                                </Button>
                                <Button
                                    tabIndex={-1}
                                    variant="primary"
                                    type="submit"
                                    disabled={
                                        isSubmitting ||
                                        !values.from ||
                                        !values.to
                                    }
                                    loading={isSubmitting}
                                >
                                    {t('form.export')}
                                </Button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}
